// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact_us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-e-commerce-js": () => import("./../src/pages/e-commerce.js" /* webpackChunkName: "component---src-pages-e-commerce-js" */),
  "component---src-pages-e-learning-js": () => import("./../src/pages/e-learning.js" /* webpackChunkName: "component---src-pages-e-learning-js" */),
  "component---src-pages-gdpr-policy-js": () => import("./../src/pages/gdpr_policy.js" /* webpackChunkName: "component---src-pages-gdpr-policy-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-team-and-values-js": () => import("./../src/pages/team_and_values.js" /* webpackChunkName: "component---src-pages-team-and-values-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../src/pages/terms_of_use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-travel-js": () => import("./../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */),
  "component---src-pages-who-we-are-js": () => import("./../src/pages/who_we_are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

